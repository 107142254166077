$(document).ready(function () {
    jQuery.fn.rotate = function(deg, duration=400) {
        $(this).animate(
            { deg: deg },
            {
                duration: duration,
                step: function(now) {
                    $(this).css({ transform: 'rotate(' + now + 'deg)' });
                }
            }
        );
        return this;
    };

    jQuery.fn.slideLeft = function(position, duration=400) {
        $(this).animate({ 'left' : position + 'px'}, duration);
        return this;
    }

    jQuery.fn.slideRight = function(position, duration=400) {
        $(this).animate({ 'left' : position + 'px'}, duration);
        return this;
    }

    $('.footer-menu').on('click tap', function() {
        $(this).siblings('.footer-column-items').slideToggle();
        var arrow = $(this).children('.footer-menu-arrow-container').children('.footer-menu-arrow');
        if (arrow.hasClass('open'))
            arrow.removeClass('open').rotate(0);
        else
            arrow.addClass('open').rotate(180);
    });

    $('.mobile-menu-toggle').on('click tap', function() {
        $('body').toggleClass('show-nav');
    });

    $('.overlay').on('click tap', function() {
        $('body').toggleClass('show-nav');
    });

    $('.mobile-submenu-toggle').on('click tap', function() {
        const submenu = $(this).siblings('.mobile-submenu');
        const list_items = $(this).closest('ul').children();
        const parent_item = $(this).parent();
        const arrow = $(this).children('.arrow');
        // cleanup
        submenu.each(closeSubmenu);
        list_items.not(parent_item).each(closeSubmenu);
        // open submenu
        submenu.slideToggle();
        if (arrow.hasClass('open'))
            arrow.removeClass('open').rotate(0);
        else
            arrow.addClass('open').rotate(180);

        function closeSubmenu() {
            $(this).find('.mobile-submenu-toggle ~ .mobile-submenu').slideUp();
            $(this).find('.arrow').removeClass('open');
        }
    });

    $( "#mainsearch-mobile" ).focusin(function() {
        $('#header-link').slideToggle();
    });

    $( "#mainsearch-mobile" ).focusout(function() {
        $('#header-link').slideToggle();
    });

    // sticky menu
    $(window).scroll(function() {
        if ($(this).scrollTop() > 120) {
            $('.navigation').addClass("sticky");
        }
        else {
            $('.navigation').removeClass("sticky");
        }
    });

    function getUrlVars(url) {
        var vars = {};
        if (url == undefined) {
            var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
                vars[key] = value;
            });
        } else {
            var parts = url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
                vars[key] = value;
            }); 
        }
        return vars;
    }

    var params= getUrlVars();

    if (params.letter != undefined) {
            var element = document.getElementById('letters-' + params.letter)
            var headerOffset = 121 + 84;
            if (Foundation.MediaQuery.is('large')) {
                headerOffset = 60 + 84;
            }
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition - headerOffset;;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });  
    }

    // document.querySelectorAll('a').forEach(anchor => {
    //     anchor.addEventListener('click', function (e) {
    //         if (this.getAttribute('href').indexOf("?") > -1) {
    //             var a = window.location.pathname.split('/');
    //             if (a[a.length - 1] == 'designers') {
    //                 e.preventDefault();
    //                 var params= getUrlVars(this.getAttribute('href'));
    //                 var element = document.getElementById('letters-' + params.letter)
    //                 var headerOffset = 121 + 84;
    //                 if (Foundation.MediaQuery.is('large')) {
    //                     headerOffset = 60 + 84;
    //                 }
    //                 var elementPosition = element.getBoundingClientRect().top + window.scrollY;
    //                 var offsetPosition = elementPosition - headerOffset;;

    //                 window.scrollTo({
    //                     top: offsetPosition,
    //                     behavior: "smooth"
    //                 });  
    //             }
    //         }
    //     });
    // });

});