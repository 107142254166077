$(document).ready(function() {
  'use strict';

  if ($('.g-recaptcha').length > 0) {
    loadCaptcha();
  }

  if ($('#contact-form').length > 0) {
    $('#contact-form').validate({
      rules: {
        firstname: "required",
        email: "required",
        subject: "required",
        date: "required",
        time: "required"
      },
      errorPlacement: () => {},
      submitHandler: function(form) {
        const $form = $(form);
  
        if ($("#privacy").prop('checked')) {
          $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            success: function(data) {
              if (data.success) {
                showMessageBox('success', msg['MSG_MESSAGE_SENT']);
              }
              else {
                showMessageBox('danger', data.errors.message);
              }
            },
            failed: function() {
              showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function() {
              grecaptcha.reset();
              expiredContactFormCaptcha();
            }
          });
        }
        else {
          grecaptcha.reset();
          expiredContactFormCaptcha();
          showMessageBox('danger', msg['MSG_PRIVACY_POLICY']);
        }
      }
    });
    
    $('#contact-form').on('submit', function(e) {
      e.preventDefault();
    
      if ($("#privacy").prop('checked')) {
        $.ajax({
          type: $("#contact-form").attr('method'),
          url: $("#contact-form").attr('action'),
          data: $("#contact-form").serialize(),
          beforeSend: function(data) {
          },
          success: function(data) {
            if (data.success) {
              showMessageBox('success', msg['MSG_MESSAGE_SENT']);
            }
            else {
              showMessageBox('danger', data.errors.message);
            }
          },
          failed: function(data) {
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
          },
          complete: function(data) {
            grecaptcha.reset();
            expiredContactFormCaptcha();
          }
        });
      }
      else {
        grecaptcha.reset();
        expiredContactFormCaptcha();
        showMessageBox('danger', msg['MSG_PRIVACY_POLICY']);
      }
    });
  }
  
});

function contactFormCaptcha() {
  $('#contact-form [type="submit"]').prop('disabled', false);
}

function expiredContactFormCaptcha() {
  $('#contact-form [type="submit"]').prop('disabled', true);
}

function loadCaptcha() {
  $.ajax({
    method: 'GET',
    url: 'https://www.google.com/recaptcha/api.js',
    dataType: 'script'
  });
}