$(document).ready(function () {
    'use strict';

    // Functions to be called on load/reload

    loadBrandBanner();


    // Jquery functions to be called once on page load
    $(window).on('popstate', function (event) {
        reloadCatalog(window.location, false);
    });

    $('#catalog-container').on('click', '#filters-dropdown .filter-name', function () {
        var $thisScrollView = $(this).next(".filter-dropdown");
        resetDropdown($thisScrollView);

        if (!$(this).parent().hasClass("open")) {
            $thisScrollView.slideDown();
            $(this).parent().addClass("open");
            window.dispatchEvent(new Event('resize')); //Hack to make nanoscroller work
        }
        else {
            $thisScrollView.slideUp();
            $(this).parent().removeClass("open");
        }
    });

    $('#catalog-container').on('click', '#filters-dropdown li > a', function (event) {
        event.preventDefault();
        applyFilter(this);
    });

    $('#catalog-container').on('click', '#layout-options input[type="radio"]', function (event) {
        setCatalogLayout(this.id.indexOf('grid') !== -1 ? 'grid' : 'list');
    });

    var search = getUrlParam('search');
    if (typeof search !== "undefined") {
        $('.no-search-found').removeClass('hide');
    }
    else {
        $('.no-products-found').removeClass('hide');
    }

    $('#sort-options').find('.sort-btn').on('click tap', function () {
        $(this).parent().find('.sort-dropdown').toggleClass('open');
    });

    $('.paginator').find('.right-arrow, .left-arrow').parent().attr('style', 'border: none');

    $('.current-filter').on('click tap', function(event) {
        event.preventDefault();
        applyFilter(this);
    });

    // Call functions
    initAddToCart();
    loadQtyInputs();
    checkPreferredLayout();
    setToggleFilterButton();
    calculateDropdownHeight();
    removeEmptyDropdowns();
    setFilterDropdownSearch();
    initMobileSliders();
    checkOpenFilters();
    // resetDropdown('#filter-dropdown-4, #filter-dropdown-5');
});

function loadBrandBanner() {
    const brandValue = $('#brand-value');
    if (brandValue.length > 0) {
        let brandBanner = $('#brand-banner').html().replace(/\{brand\}/g, brandValue.text());
        $('#brand-banner').html(brandBanner).addClass('ready');
        brandValue.remove();
    }
}

function calculateDropdownHeight() {
    $('#filters-dropdown .nano-content').each(function () {
        var totalHeight = 16;
        $(this).find('.filter').each(function () {
            totalHeight += $(this).outerHeight();
        });
        totalHeight = Math.min(totalHeight, 220);
        $(this).attr('style', 'height: ' + totalHeight + 'px;');
    });
}

function removeEmptyDropdowns() {
    $('.filter-dropdown .nano-content').each(function () {
        var items = $(this).find('li').toArray();

        if (items.length == 0) {
            $(this).closest('.filter-tag').hide();
        }
    });
}

function setFilterDropdownSearch() {
    $('.filter-dropdown .tag-search input').on('input', function () {
        searchTag(this);
    });
    $('.filter-dropdown .tag-search form').on('submit', function (e) {
        e.preventDefault();
        searchTag($(this).find('input'));
    })

    function searchTag(input) {
        var query = $(input).val().toLowerCase();
        $(input).closest('.filter-dropdown').find('.filter').each(function () {
            if (!$(this).find('.label').text().toLowerCase().includes(query)) {
                $(this).attr('style', 'display: none;');
            }
            else {
                $(this).attr('style', '');
            }
        });
    }
}

function setToggleFilterButton() {
    $('#toggle-filters-button').on('click tap', function () {
        const $this = $(this);
        $('#filters-dropdown').addClass('init');

        if ($this.hasClass('open')) {
            $this.removeClass('open');

            $('#filters-dropdown')
                .removeClass('open')
                .slideUp(400);
        }
        else {
            $this.addClass('open');

            $('#filters-dropdown')
                .addClass('open')
                .slideDown(400);
        }
    });
}

function resetDropdown($excluded) {
    var $dropDowns = $('#catalog-wrapper #filters-dropdown .filter-dropdown').not($excluded);
    $dropDowns.slideUp(0, function () {
        $('#catalog-wrapper #filters-dropdown').css({ opacity: 1 });
    });
    $dropDowns.parent().removeClass("open");
    $dropDowns.find(".active").not(".active-original").removeClass("active");
    $dropDowns.find(".active-original").not(".active").addClass("active");
}

function applyFilter(filter) {
    reloadCatalog(filter.href, true);
}

function reloadCatalog(url, push, currentDropdown) {
    $('#catalog-container').addClass('catalog-loading');
    $.ajax({
        url: url,
        type: "GET",
        dataType: "html",
        complete: function (jqXHR) {
            if (jqXHR.status >= 200 && jqXHR.status < 300) {
                var response = jqXHR.responseText;

                $('#catalog-container').html($("<div>").append($.parseHTML(response)).find("#catalog-wrapper"));

                if (push) {
                    window.history.pushState(null, document.title, url);
                }

                $(".nano").nanoScroller();
                $('html, body').removeClass('is-popup-open');

                $('#sort-options').find('.sort-btn').on('click tap', function () {
                    $(this).parent().find('.sort-dropdown').toggleClass('open');
                });

                $('.paginator').find('.right-arrow, .left-arrow').parent().attr('style', 'border: none');

                $('.current-filter').on('click tap', function(event) {
                    event.preventDefault();
                    applyFilter(this);
                });

                loadBrandBanner();
                initAddToCart();
                loadQtyInputs();
                checkPreferredLayout();
                calculateDropdownHeight();
                setToggleFilterButton();
                removeEmptyDropdowns();
                setFilterDropdownSearch();
                initMobileSliders();
                checkOpenFilters();
                resetDropdown('#filter-dropdown-4, #filter-dropdown-5');
            }
            else {
                if (jqXHR.responseText.includes('Too many filters')) {
                    showMessageBox('danger', general_toomanyfilters);
                }
                else {
                    if (push) {
                        window.history.pushState(null, document.title, url);
                    }
                    window.location.reload();
                }
            }

            $('#catalog-container').removeClass('catalog-loading');
        }
    });
}

function checkPreferredLayout() {
    var layout = localStorage.getItem('catalogLayout');
    if (layout && layout === 'list') {
        $('#list-option').prop('checked', true).trigger('click');
    }
    else {
        layout = 'grid';
        $('#grid-option').prop('checked', true).trigger('click');
    }

    setCatalogLayout(layout);
}

function setCatalogLayout(layout) {
    if (layout === 'grid') {
        $('#catalog-wrapper .list-layout').hide();
        $('#catalog-wrapper .grid-layout').fadeIn();
    }
    else {
        $('#catalog-wrapper .grid-layout').hide();
        $('#catalog-wrapper .list-layout').fadeIn();
    }

    localStorage.setItem('catalogLayout', layout);
}

function initAddToCart() {
    $('.product-container .addtocart-form').not('.addtocart-initialized')
        .on('submit', function (event) {
            event.preventDefault();
            const $form = $(this);
            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                headers: { 'Accept': 'application/json' },
                success: function (data) {
                    if (!data.success) {
                        if (data.code == 'MIXED_INTANGIBLE') {
                            showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                        }
                        else if (data.code == 'MIXED_CART') {
                            showMessageBox('danger', msg['MSG_MIXED_CART']);
                        }
                        else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
                            showMessageBox('danger', (msg['MSG_RULE_REJECT'] || '').replace(/:rule/g, data.rejectCartRuleName))
                        }
                        else if (data.code == 'LOGIN_REQUIRED') {
                            location.href = '/' + $('html').attr('lang') + '/login';
                        }
                        else {
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        }
                    } else {
                        rewixUpdateCart($form);
                        $form.find('.quantity-container').each(function () {
                            const value = $(this).val();
                            $(this).data('qtyorig', value);
                        });
                        $form.find('[data-matomo]').each(function () {
                            const $this = $(this);
                            let matomoData = $this.data('matomo');
                            matomoData.initQuantity = $this.val() >> 0;
                            $this.data('matomo', matomoData);
                        });
                        $form.find('[type="submit"]').prop('disabled', true);
                        $form.trigger('cart:update');
                        showMessageBox('success', msg['MSG_ADDEDTOCART']);
                    }
                },
                error: function () {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
            });
        })
        .addClass('addtocart-initialized');
}

function getUrlParam(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
}

function checkOpenFilters() {
    $('#filters-dropdown .filter-name').each(function() {
        var $thisScrollView = $(this).next(".filter-dropdown");
        resetDropdown($thisScrollView);

        if ($(this).parent().hasClass("to-open")) {
            queueMicrotask(() => {
                $thisScrollView.slideDown();
                $(this).parent()
                    .addClass("open")
                    .removeClass('to-open');
                window.dispatchEvent(new Event('resize')); //Hack to make nanoscroller work
            });
        }
        else {
            queueMicrotask(() => {
                $thisScrollView.slideUp();
            });
        }
    });
}

function initMobileSliders() {
    $('.product-picture-slider.mobile').each(function () {
        $(this).slick({
            speed: 500,
            autoplay: false,
            arrows: false,
            dots: false,
            focusOnSelect: false,
            adaptiveHeight: false,
            pauseOnFocus: false,
            infinite: true,
            touchMove: true,
            touchThreshold: 4,
            slidesToShow: 1,
            slidesToScroll: 1,
        });
    });
}