$(function() {
    const emailForm = $('#password-new-form-email');
    const tokenForm = $('#password-new-form-token');

    emailForm.validate({
        submitHandler: function() {
            $.ajax({
                method: emailForm.attr('method'),
                url: emailForm.attr('action'),
                data: emailForm.serialize(),
                dataType: 'json',
                success: function(data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_TOKEN_SENT']);
                        emailForm.addClass('hide');
                        tokenForm.removeClass('hide');
                        $('#email').val($('#recoveremail').val());
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                },
                error: function() {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            });
        }
    });

    tokenForm.validate({
        submitHandler: function() {
            $.ajax({
                method: tokenForm.attr('method'),
                url: tokenForm.attr('action'),
                data: tokenForm.serialize(),
                dataType: 'json',
                success: function(data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_PASSWORD_UPDATED'], null, function() {
                            window.location.href = tokenForm.data('nexturl') ?? '/';
                        });
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                },
                error: function() {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            });
        }
    });
});