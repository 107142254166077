$(document).ready(function () {

    // show hide password recovery
    $('.js-toggle-show').on('click', function (event) {
        event.preventDefault();
        var $this = $(this);

        $($this.attr('data-show')).slideDown();
        $($this.attr('data-hide')).slideUp();
    });

    if (!$('.navigation').length) {
        $('.wrapper').css('margin-top', 0)
    }

    function exists(selector) {
        try {
            return $(selector).length > 0;
        }
        catch {
            return false;
        }
    }

    $('#subscribeFormSubmit').on('click', function () {
        $('#subscribe-form').trigger('submit');
    })

    
    $('#buyer').css('display', 'none');
    $('#selectBuyer').on('change', function () {
        
        if ($('#selectBuyer').val() == 'yes') {
            $('#buyer').css('display', 'block')
        } else {
            $('#buyer').css('display', 'none')
        }
    })

    $('#i_country_id').on('change', function () {
        
        if ($('#i_country_id').val() == "108") {
            $('#sdi_test').css('display', 'block')
        } else {
            $('#sdi_test').css('display', 'none')
        }
    })

    $('#subscribe-form').validate({
        errorPlacement: () => { },
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: function (form) {
            const $form = $(form);
            if ($('#password1').val() != $('#password2').val()) {
                showMessageBox('danger', msg['MSG_PASSWORD_NOT_EQUAL']);
            }
            else {
                $.ajax({
                    type: $form.attr('method'),
                    url: $form.attr('action'),
                    data: $form.serialize(),
                    dataType: 'json',
                    beforeSend: function () {
                        $form.find(':input').prop('disabled', true);
                    },
                    success: function (data) {
                        if (data.success) {
                            if (window.dataLayer) {
                                pushGTMEventWithCallback(500, {
                                    event: 'sign_up',
                                    eventCallback: GTMCallback(() => { window.location = $form.data('redirectsuccess'); })
                                });
                            }
                            else {
                                window.location = $form.data('redirectsuccess');
                            }
                        }
                        else {
                            showMessageBox('danger', msg[data.errors.message] ?? msg['MSG_GENERIC_ERROR_WITH_CODE'].replace(':code', data.errors.message));
                        }
                    },
                    failed: function () {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    },
                    complete: function () {
                        $form.find(':input').prop('disabled', false);
                    }
                });
            }
        }
    })

    if ($('#subscribe-form-showroom').length) {
        $.validator.messages.required = messages_requiredfield;
    }

    $('#subscribe-form-showroom').validate({
        rules: {
            username: {
				email: true
			},
            buyer_email: {
				email: true
			},
		},

        messages: {
			username: {
				email: msg["ERR_INVALIDMAIL"]
            },
            buyer_email: {
				email: msg["ERR_INVALIDMAIL"]
			},
        },
        errorPlacement: function (error, element) {
			if (!element.is("[type=checkbox]")) {
                error.insertAfter(element);
            }
		},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: function (form) {
            const $form = $(form);
            
            const resultForm = $form.find('[name]').toArray().reduce((result, input) => {
                if ((input.type == "checkbox" || input.type == "radio") && !input.checked) {
                    return result;
                }
                if (!input.name.startsWith('buyer_')) {
                    if (result[input.name]) {
                        if (!(result[input.name] instanceof Array)) {
                            result[input.name] = [
                                result[input.name]
                            ]
                        }
                        result[input.name].push(input.value);
                    } else {
                        result[input.name] = input.value;
                    }
                } else {
                    if ($('input[name*="buyer_"]').val() != '') {
                        if ($('#buyer_cel').val() == '') {
                            if (input.name!='buyer_cel_prefix' ) {
                                if (!result['note']) {
                                    result['note'] = '';
                                }
                                result['note'] += input.value + " ";
                            }
                        } else {
                            if (!result['note']) {
                                result['note'] = '';
                            }
                            result['note'] += input.value + " ";
                        }
                    }
                }
                return result
            },{})

            if ($('#password1').val() != $('#password2').val()) {
                showMessageBox('danger', msg['MSG_PASSWORD_NOT_EQUAL']);
            }
            else {
                $.ajax({
                    type: $form.attr('method'),
                    url: $form.attr('action'),
                    data: Object.entries(resultForm).reduce((result, pair) => {
                        if (pair[1] instanceof Array) {
                            for (const value of pair[1]) {
                                result.push(`${encodeURIComponent(pair[0])}=${encodeURIComponent(value)}`)
                            }
                        } else {
                            result.push(`${encodeURIComponent(pair[0])}=${encodeURIComponent(pair[1])}`)
                        }
                        return result
                    },[]).join("&"),
                    dataType: 'json',
                    beforeSend: function () {
                        $form.find(':input').prop('disabled', true);
                    },
                    success: function (data) {
                        if (data.success) {
                            if (window.dataLayer) {
                                dataLayer.push({
                                    event: 'sign_up',
                                    eventCallback: GTMCallback(() => { window.location = $form.data('redirectsuccess'); })
                                })
                            }
                            else {
                                window.location = $form.data('redirectsuccess');
                            }
                        }
                        else {
                            showMessageBox('danger', msg[data.errors.message]);
                        }
                    },
                    failed: function () {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    },
                    complete: function () {
                        $form.find(':input').prop('disabled', false);
                    }
                });
            }
        }
    })

    $('#consent4').change(function () {
        var i = 1;
        if ($(this).prop('checked')) {
            for (i = 5; i <= 6; i++) {
                $('#consent' + i).prop('checked', true);
            }
        } else {
            for (i = 5; i <= 6; i++) {
                $('#consent' + i).prop('checked', false);
            }
        }
    });

    $('#login-block').slideToggle();
    $('.show-for-login').fadeToggle(0);

    $('.login-toggle').on('click tap', function () {
        $('html, body').animate({
            scrollTop: $(".login-subscribe-container").offset().top
        }, 400);
        $('#subscribe-block, #login-block').slideToggle();
        $('.show-for-login, .show-for-subscribe').fadeToggle(0);
    });

    $('#subscribe-form-part-2').slideToggle();
    $('.show-for-part-2').fadeToggle(0);

    $('#subscribe-part-toggle').on('click tap', function () {
        if ($('#subscribe-form-part-1 input').valid()) {
            $(this).toggleClass('btn-primary').toggleClass('btn-secondary');
            $('html, body').animate({
                scrollTop: $(".login-subscribe-container").offset().top
            }, 400);
            $('#subscribe-form-part-1, #subscribe-form-part-2').slideToggle();
            $('.show-for-part-1, .show-for-part-2').fadeToggle(0);
        }
    });

});