function showMessageBox(type, message, extra, onClose=function(){}) {
    var boxType = 'alert-' + type;
    var content = '';
    if (extra) {
        content = '<div>' + extra.content + '</div>';
    }
    var messageBox = '<div class="alert ' + boxType + ' fade in">' + message + content + '</div>';
    $('div.alert-box').html(messageBox);
    setTimeout(function() {
        $('div.alert-box').html('');
        onClose();
    }, 5000);
}