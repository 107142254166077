$(document).ready(function() {

    $(".nano").nanoScroller();

    $('.shipping-info').on('click tap', function() {
        $(this).toggleClass('open');
    });

    $('.category-selector .link').on('click tap', toggleSlider);

    function toggleSlider() {
        if (! $(this).hasClass('selected')) {
            // Deselect & hide previously selected
            var selected = $('.category-selector').find('.selected');
            var id = selected.parent().attr('id').replace('selector__', '');
            $('#slider__' + id).addClass('hidden');
            selected.removeClass('selected');

            // Select and show newly selected
            id = $(this).parent().attr('id').replace('selector__', '');
            $('#slider__' + id).removeClass('hidden');
            $(this).addClass('selected');
        }
    }
});

if ($('.product-page')[0] && $(window).width() >= 1024) {
    const breadcrumb = $('.breadcrumb');
    $('.header-sticky').addClass('sticky');
    $('.info-container').addClass('sticky').attr('style', 'top: ' + breadcrumb.offset().top + 'px;');
    $('.pictures-container').addClass('sticky').attr('style', 'top: ' + breadcrumb.offset().top + 'px;');

    breadcrumb.on('scroll', function() {
        if (this.scrollTop > 0) {
            $(this).slideUp();
        }
        else {
            $(this).slideDown();
        }
    });
}

$('.pictures-container .picture').on('click tap', function() {
    var index = $(this).attr('id').replace('image_thumbnail__', '');
    var imageId = '#image__' + index;
    $('html, body').animate({
        scrollTop: $(imageId).offset().top - $('.breadcrumb').offset().top
    }, 400);
});

const productImages = $('.product-image-scroller .product-image').toArray();

$(window).on('scroll', function() {
    const scrollTop = $(this).scrollTop();
    for (let index = 0; index < productImages.length; index++) {
        const top = $('.product-image-scroller').offset().top + $(productImages[index]).offset().top;
        if (scrollTop < top) {
            const thumbnailId = '#image_thumbnail__' + (index + 1);
            $('.pictures-container .picture.selected').removeClass('selected');
            $(thumbnailId).addClass('selected');
            break;
        }
    }
});

$('.product-image, .picture-slide').on('click tap', function() {
    var slideId;
    if ($(this).hasClass('product-image')) {
        slideId = '#modal_slide__' + $(this).attr('id').replace('image__', '');
    }
    else {
        slideId = '#modal_slide__' + $(this).attr('id').replace('picture_slide__', '');
    }

    $('.product-picture-modal').addClass('show');
    $(slideId).addClass('selected');
});

$('.product-picture-modal').on('click tap', function() {
    $('.product-picture-modal').removeClass('show');
    $('.modal-slide').removeClass('selected');
});

$('.modal-arrow-left').on('click tap', function(e) {
    var prevId = '#' + $('.modal-slide.selected').removeClass('selected').attr('prev');
    $(prevId).addClass('selected');
    e.stopPropagation();
});

$('.modal-arrow-right').on('click tap', function(e) {
    var nextId = '#' + $('.modal-slide.selected').removeClass('selected').attr('next');
    $(nextId).addClass('selected');
    e.stopPropagation();
});

function loadQtyInputs() {
    $('.i-number').each(function() {
        const $this = $(this);

        if (!$this.data('initialized')) {
            const max = $this.data('max') >> 0;
            const min = $this.data('min') >> 0;
            const initValue = $this.val();
            let restoreColor = () => {};
    
            if (initValue > max || initValue < min) {
                $this.css('color', 'red');
                restoreColor = () => $this.css('color', '');
            }
    
            $('<button class="i-number-btn i-number-minus" tabindex="-1"><img src="/skins/romanelli/images/icons/qnt-min.svg"/></button>')
                .insertBefore($this)
                .on('click', function(event) {
                    event.preventDefault();
                    const value = getCurrentValue($this);
                    checkValue(value - 1);
                });
            
            $('<button class="i-number-btn i-number-plus" tabindex="-1"><img src="/skins/romanelli/images/icons/qnt-plus.svg"/></button>')
                .insertAfter($this)
                .on('click', function(event) {
                    event.preventDefault();
                    const value = getCurrentValue($this);
                    checkValue(value + 1);
                });
    
            $this.on('keydown input', function(event) {
                const value = getCurrentValue($this);
                const key = (event.key || '').toUpperCase();

                if (key == 'ENTER') {
                    event.preventDefault();
                    $this.closest('form').trigger('submit');
                }
                else if (key == 'ARROWUP') {
                    checkValue(value + 1);
                }
                else if (key == 'ARROWDOWN') {
                    checkValue(value - 1);
                }
                else {
                    checkValue(value);
                }
            });

            $this.data('initialized', true);
    
            function getCurrentValue($this) {
                return $this.val().toString().replace(/[^0-9]/g, '') >> 0;
            }
    
            function checkValue(value) {
                if (value > max) {
                    $this.val(max);
                }
                else if (value < min) {
                    $this.val(min);
                }
                else {
                    $this.val(value);
                }
                restoreColor();
                checkInputEnabled();
            }

            function checkInputEnabled() {
                const $form = $this.closest('form');
                const totalQuantity = $form.find('.i-number').toArray()
                    .map(input => $(input).val())
                    .reduce((a,b) => a + b, 0);
                
                if (totalQuantity > 0) {
                    $form.find('[type="submit"]').prop('disabled', false);
                }
                else {
                    $form.find('[type="submit"]').prop('disabled', true);
                }
            }
        }
    });
}