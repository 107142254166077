$(document).ready(function() {
    $('.dropshipping-show-info').on('click', function() {
        $('.dropshipping-box.single .box-description').slideUp();
        $('.dropshipping-box.single .btn')
            .addClass('hide')
            .filter('.dropshipping-show-info').removeClass('hide');
        $(this).closest('.dropshipping-box').find('.box-description').slideDown();
        $(this)
            .addClass('hide')
            .siblings('.btn').removeClass('hide');
    });

    $('.dropshipping-box [id*="consent"]').on('change', function() {
        const $this = $(this);
        const id = $this.attr('id').replace('consent-', '');
        if ($this.is(':checked')) {
            $(`#terms-${id}, #contract-${id}`).val('true');
        }
        else {
            $(`#terms-${id}, #contract-${id}`).val('false');
        }
    });

    $('.dropshipping-addtocart').on('submit', function(event) {
        event.preventDefault();
        const $form = $(this);
        const consent = $form.closest('.dropshipping-box').find('[id*="consent"]');

        if (consent.is(':checked')) {
            $.ajax({
                method: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                success: function(data) {
                    if (data.success) {
                        const selectedOption = $form;
                        const matomoDataSignup = selectedOption.data('matomo-signup');
                        const matomoDataRecurring = selectedOption.data('matomo-recurring');
                        const dummyElement = $(`
                            <div>` +
                            (matomoDataSignup ? `
                                <input type="hidden" value="1" data-matomo='${JSON.stringify(matomoDataSignup)}'>
                            ` : '') + `
                                <input type="hidden" value="1" data-matomo='${JSON.stringify(matomoDataRecurring)}'>
                            </div>
                        `);
                        rewixUpdateCart(dummyElement);
                        initializePayment(data.data);
                    }
                    else if (data.code == 'MIXED_INTANGIBLE') {
                        showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                    }
                    else if (data.code == 'INSUFFICIENT_STOCK') {
                        showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                    }
                    else if (data.errors && data.errors.message == 'You can only have one subscription active.') {
                        showMessageBox('danger', msg['MSG_ONLY_ONE_SUBSCRIPTION_ACTIVE']);
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                },
                error: function() {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            });
        }
        else {
            showMessageBox('danger', msg['ERR_PRIVACY']);
        }
    });


    function initializePayment(orderId) {
        $.ajax({
            type: 'POST',
            url: '/restful/shopping/checkout/payment/initialize',
            data: { orderId: orderId },
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location = '/current/checkout/billing';
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    }
});