setInterval(function() {
    var salesList = document.getElementsByClassName("sales-item");

    for (i = 0; i < salesList.length; i++) {
        var currentSales = salesList[i];
        var endtime = $(currentSales).data( "endtime" );

        var countDownDate = new Date(endtime).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        $(currentSales).find('.sales-item-countdown-date').text(days + "d " + hours + "h "
            + minutes + "m " + seconds + "s ");
    }
}, 1000);
