$(document).ready(function() {

    $('#best-brands').not('.slick-initialized').slick({
        infinite: true,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        pauseOnFocus: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                arrows: false,
                settings: {
                    slidesToShow: 3,
                    dots: true,
                }
            },
            {
                breakpoint: 640,
                arrows: false,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                }
            }
        ]
    });

    $('#banner-slider').not('.slick-initialized').slick({
        speed: 500,
        autoplay: false,
        arrows: true,
        dots: false,
        focusOnSelect: false,
        adaptiveHeight: false,
        pauseOnFocus: false,
        infinite: true,
        touchMove: true,
        touchThreshold: 4,
        nextArrow: '#banner-slider-arrow-right',
        prevArrow: '#banner-slider-arrow-left'
    });

    $('.category-products-slider').not('.slick-initialized').each(function() {
        var leftArrow = $(this).siblings().find('.slider-arrow-left').first();
        var rightArrow = $(this).siblings().find('.slider-arrow-right').first();
        $(this).slick({
            speed: 500,
            autoplay: false,
            arrows: true,
            dots: false,
            focusOnSelect: false,
            adaptiveHeight: false,
            pauseOnFocus: false,
            infinite: true,
            touchMove: true,
            touchThreshold: 4,
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: rightArrow,
            prevArrow: leftArrow,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2,
                        dots: true
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        nextArrow: '<div/>',
                        prevArrow: '<div/>',
                        autoplay: true,
                        dots: true
                    }
                },
            ]
        });
    });
});